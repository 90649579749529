import React from "react";
import { Helmet } from "react-helmet"
import {Box, Text, useColorModeValue, Image, Flex, chakra, Button, HStack,Input} from "@chakra-ui/react";
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next';
import {graphql} from "gatsby";
import { today, now, getLocalTimeZone } from "@internationalized/date";
import {Link, useI18next} from 'gatsby-plugin-react-i18next';
import bg from "../images/bg.svg"
import ema_logo from "../images/ema_logo.svg"
import complaint_art from "../images/complaint_art_ema.svg"
import {Spacer, Stack, VStack} from "@chakra-ui/layout";
import { navigate } from 'gatsby';
import CheckComplaintForm from "../components/checkComplaintForm";
// import {CountrySelectWithIcon} from "react-phone-number-input/modules/CountrySelect";
import {Select} from "chakra-react-select";
import Layout from "../components/layout";
import {DatePicker} from "../components/Calendar/DatePicker";
import {I18nProvider} from '@react-aria/i18n';
import {Calendar} from "../components/Calendar/Calendar";
import {SSRProvider} from "@react-aria/ssr";
import {OverlayContainer} from "@react-aria/overlays";
function Cards(){

    return (
        <Flex
            bg="red"
            p={50}
            w="full"
            alignItems="center"
            justifyContent="center"
        >
            <Box
                mx="auto"
                rounded="lg"
                shadow="md"
                bg={useColorModeValue("white", "gray.800")}
                maxW="2xl"
            >
                <Image

                    roundedTop="lg"
                    w="full"
                    h={64}
                    fit="cover"
                    src="https://images.unsplash.com/photo-1550439062-609e1531270e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60"
                    alt="Article"
                />

                <Box p={6}>
                    <Box>
                        <chakra.span
                            fontSize="xs"
                            textTransform="uppercase"
                            color={useColorModeValue("brand.600", "brand.400")}
                        >
                            Product
                        </chakra.span>
                        <Link
                            display="block"
                            color={useColorModeValue("gray.800", "white")}
                            fontWeight="bold"
                            fontSize="2xl"
                            mt={2}
                            _hover={{ color: "gray.600", textDecor: "underline" }}

                        >
                            I Built A Successful Blog In One Year
                        </Link>
                        <chakra.p
                            mt={2}
                            fontSize="sm"
                            color={useColorModeValue("gray.600", "gray.400")}
                        >
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Molestie
                            parturient et sem ipsum volutpat vel. Natoque sem et aliquam
                            mauris egestas quam volutpat viverra. In pretium nec senectus
                            erat. Et malesuada lobortis.
                        </chakra.p>
                    </Box>

                    <Box mt={4}>
                        <Flex alignItems="center">
                            <Flex alignItems="center">
                                <Image
                                    h={10}
                                    fit="cover"
                                    rounded="full"
                                    src="https://images.unsplash.com/photo-1586287011575-a23134f797f9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=48&q=60"
                                    alt="Avatar"
                                />
                                <Link
                                    mx={2}
                                    fontWeight="bold"
                                    color={useColorModeValue("gray.700", "gray.200")}

                                >
                                    Jone Doe
                                </Link>
                            </Flex>
                            <chakra.span
                                mx={1}
                                fontSize="sm"
                                color={useColorModeValue("gray.600", "gray.300")}
                            >
                                21 SEP 2015
                            </chakra.span>
                        </Flex>
                    </Box>
                </Box>
            </Box>
        </Flex>
    );
};


function IndexPage() {
    const { t } = useTranslation();
    const {languages, originalPath, language,  changeLanguage} = useI18next();
  return (

    <Box position="relative"  >
        <Helmet>
            <meta charSet="utf-8" />
            <title>EMA Complaint Handling System</title>
            <link rel="canonical" href="https://ema.damadigit.com/" />
        </Helmet>
        <Layout>

            <Box position="absolute" top={0} left={0} backgroundImage={bg} width="85vw" height="100vh" ml={[50,100,200]} backgroundSize="cover"/>

            <Box position="absolute" top={10} left={6}>
                <Image src={ema_logo} width={[75,100,200]} />

            </Box>
            <Box position="absolute" top={10} left={["150px", 60, 80]} >

                <Text color="white" fontSize={["lg","xl","3xl"]} fontWeight="bold">የኢትዮጵያ መገናኛ ብዙሃን ባለስልጣን</Text>
                <Text mt="-10px" color="white" fontSize={["l","xl","3xl"]} fontWeight="bold">Ethiopian Media Authority </Text>
            </Box>

            <Box position="absolute" top={["100px",40,40]} left={0} w={"full"} >
                <Stack  direction={['column','column', 'row']}  m={3} >
                    <Box mb={10}  w={80} >
                        <VStack>





                            <Text  fontSize={["2xl","4xl","4xl"]} fontWeight="bold"><Trans>Say No to FakeNews and HateSpeech</Trans></Text>
                            <Text    fontSize={["lg","xl","xl"]} fontWeight="bold"><Trans>Share Us your complaint</Trans></Text>
                            <Box >
                                <Button mt={"20px"}

                                        onClick={()=>navigate("/fileComplaint")}
                                        size="lg" variant='grad'
                                >
                                    {t("Make Complaint")}
                                </Button>
                            </Box>

                        </VStack>

                    </Box>
                    <Spacer/>


                    <Box p={3}   borderRadius={10} h={200}  bg="rgba(177,234,255,0.75)"   >
                        <CheckComplaintForm/>
                    </Box>



                </Stack>
                <Box ml={[0,0,"300px"]} mt={[-10,-20,-40]}  >
                    <Image width={[300,500,600]} src={complaint_art} />
                </Box>

            </Box>

            {/*<Select position="absolute" top={0} right={50} w={100} placeholder="Language">*/}
            {/*    <option value="1"><div><Image scr={complaint_art} w={20} />Amharic</div></option>*/}
            {/*    <option value="2">Option 2</option>*/}
            {/*    <option value="3">Option 3</option>*/}
            {/*</Select>*/}




            {/*/!*{language}*!/*/}
            {/*<ul className="languages">*/}
            {/*    {languages.map((lng) => (*/}
            {/*        <li key={lng}>*/}
            {/*            <Link to={originalPath} language={lng}>*/}
            {/*                {lng}*/}
            {/*            </Link>*/}
            {/*        </li>*/}
            {/*    ))}*/}
            {/*</ul>*/}

            {/*<Cards/>*/}

        </Layout>
    </Box>




  );
}

export default IndexPage;
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
