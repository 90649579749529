import React, {useState} from 'react';
import {VStack} from "@chakra-ui/layout";
import {Box, Button, HStack, Input, Text} from "@chakra-ui/react";
import {Trans, useTranslation} from "gatsby-plugin-react-i18next";
import "niceware/browser/niceware"
import {fromArrayToHex, fromHex} from "../helpers/conversion";
import { navigate } from 'gatsby';
const CheckComplaintForm = () => {
    const { t } = useTranslation();
    const [error, setError] = useState()
    const [code, setCode] = useState()
    const hex = /[0-9A-Fa-f]{6}/g;
    const handleCheckComplaint = () => {
        if(code)
        {
            if(hex.test(code)) {
                navigate(`/complaint?code=${code}`)
            }
            else {

                try {
                    if (typeof window !== `undefined`) {


                        const text = code.toLowerCase().replace(/,/g, ' ').replace(/\s+/g, ' ')
                        const byteArray = window.niceware.passphraseToBytes(text.split(' '))
                        const hexCode = fromArrayToHex(byteArray)
                        navigate(`/complaint?code=${hexCode}`)
                    }


                } catch (e) {
                    setError(e.message)
                }


            }



        }
        else {


            setError('Please Input the code')
        }
    }
    return (
        <VStack align="left">
            <Text fontWeight="bold" fontSize="2xl"><Trans>Already Made a Complaint?</Trans></Text>
            <Text><Trans>Follow up using your code</Trans></Text>
            <HStack>
                <Input value={code} onChange={ev=>setCode(ev.target.value)} bg='white' placeholder={t("Type Your Code Here")} size='md' />
                <Button onClick={handleCheckComplaint} variant="grad"><Trans>View</Trans></Button>
            </HStack>
            <Text color="red"><Trans>{error}</Trans></Text>

        </VStack>

    );
};

export default CheckComplaintForm;
